import React, { Component } from "react";
import Slider from "react-slick";

import './index.scss';

class SimpleSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const data = this.props.items;

    return (
      <Slider {...settings}>
        {data.map(item => (
          item.image.length > 0 &&
            <div className="slider-item" key={item.id}>
                <img
                    src={item.image[0].url}
                    alt=""
                    className="slider-item__image"
                />
                <h2 className="slider-item__title">{item.title}</h2>
            </div>
        ))}
      </Slider>
    );
  }
}

export default SimpleSlider
