import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from "react-markdown";
import Layout from '@components/Layout'
import Content from '@components/Content'
import SimpleSlider from '@components/Slider'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const IndexPage = ({ data }) => (
  <Layout>
    <SimpleSlider items={data.strapiPage.Slider} />
    <Content>
      <ReactMarkdown source={data.strapiPage.content} />
    </Content>
  </Layout>
)
export default IndexPage

export const pageQuery = graphql`
  query MainPageQuery {
    strapiPage(slug: {eq: "/"}) {
        id
        content
        title
        content
        created_at
        Slider {
          title
          image {
            url
          }
      }
    }
  }
`
